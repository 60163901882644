/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'fb:app_id',
          content: '518648604862270',
        },
        {
          property: 'og:type',
          content: 'blog',
        },
        {
          property: 'og:image',
          content: 'https://bookmemate.com/social.jpg',
        },
        {
          property: 'og:url',
          content: 'https://bookmemate.com',
        },
        {
          property: 'og:site_name',
          content: 'Bookmemate',
        },
        {
          property: 'fb:admins',
          content: 'thomasdittmar3184',
        },
        {
          name: 'Revisit-After',
          content: '7 days',
        },
        {
          name: 'google-site-verification',
          content: 'ON2wCfLmcIN2OXXRRtW-Imjm_az53rxJ9qLUgmLMilY',
        },
        {
          name: 'alexaVerifyID',
          content: 'KNMMy2SLNQKpptXYX52EMGLuF9k',
        },
        {
          name: 'Classification',
          content:
            'Internet, Computers, Melbourne, Australia, Business Management Software, Online booking',
        },
        {
          name: 'Identifier',
          content: '(SCHEME=URI)https://bookmemate.com',
        },
        {
          name: 'Rating',
          content: 'General',
        },
        {
          name: 'Author',
          content: 'Bookmemate',
        },
        {
          name: 'Creator',
          content: 'Bookmemate',
        },
        {
          name: 'Creator',
          content: '(SCHEME=EMAIL)support@bookmemate.com',
        },
        {
          name: 'Creator',
          content: '(SCHEME=URL)https://bookmemate.com',
        },
        {
          name: 'Publisher',
          content: 'Bookmemate',
        },
        {
          name: 'Rights',
          content: 'Copyright 2017 Bookmemate',
        },
        {
          name: 'norton-safeweb-site-verification',
          content:
            'ly2l7qm6hd8ntgt4xfc8e3248ikry-ef3rvn9htsrkpj65dnokqfwa3ivjpvlk6zn7ctffxx9vdrnragcavo74audfsav3pifbx-rhrvo5kcayzr6nerew-r3rvmarsy',
        },
      ].concat(meta)}
    >
      <script
        type="text/javascript"
        src="https://cdn.ywxi.net/js/1.js"
        async
      ></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
